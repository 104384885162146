<template>
  <div class="row no-gutters align-items-center transfer-item">
    <el-checkbox :key="index" :value="true" @change="handleItemClick">{{ source.nickname }}</el-checkbox>
  </div>
</template>

<script>
export default {
  name: 'item-component',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    handleItemClick() {
      this.$emit('deleteMsg', this.index);
    }
  }
}
</script>
